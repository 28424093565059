<template>
  <div id="misc">
    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">Page Not Found</span>
      </h2>
      <p class="text-sm">
        We couldn't find the page you are looking for
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/error.png"
        ></v-img>
      </div>

      <btn
        label="Back to home"
        class="mb-4"
        :icon="icons.mdiChevronLeft"
        @click="home()"
      ></btn>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  methods: {
    home() {
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
